import axios, {AxiosResponse} from 'axios'
import {ClientCodeModel} from '../../get_started/core/_models'
import {
  AuthModel,
  Client,
  PricingResponseItem,
  UserModel,
  RequestAccountModel,
  Agency,
  ClientAIResponsesModel,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token/`
export const LOGIN_URL = `${API_URL}/login/`
export const UPDATE_AI_URL = `${API_URL}/update_ai/`
export const REGISTER_URL = `${API_URL}/register/`
export const REQUEST_ACCOUNT_URL = `${API_URL}/request_account/`
export const REQUEST_ACCOUNT_RESEND_CODE_URL = `${API_URL}/request_account/resend/`
export const REQUEST_ACCOUNT_CONFIRM_URL = `${API_URL}/request_account/confirm/`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password/`

interface EnableSalesforceResponse {
  oauth_link: string
}

interface EnableServiceFusionResponse {
  oauth_link: string
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

export function loginWithPhone(phoneNumber: string) {
  return axios.post<AuthModel>(`${API_URL}/login/phone/`, {
    phoneNumber,
  })
}

export function loginWithPhoneConfirm(phoneNumber: string, code: string) {
  return axios.post<AuthModel>(`${API_URL}/login/phone/confirm/`, {
    phoneNumber,
    code,
  })
}

export function updateAiEnabled(aiEnabled: boolean) {
  return axios.post<Client>(UPDATE_AI_URL, {
    ai_enabled: aiEnabled,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return AuthModel
export function requestAccount(
  email: string,
  firstname: string,
  lastname: string,
  phoneNumber: string,
  password: string
) {
  return axios.post<RequestAccountModel>(REQUEST_ACCOUNT_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    phone_number: phoneNumber,
    password,
  })
}

export function requestAccountResendCode(phoneNumber: string) {
  return axios.post(REQUEST_ACCOUNT_RESEND_CODE_URL, {
    phone_number: phoneNumber,
  })
}

export function requestAccountPhoneVerify(phoneNumber: string, code: string) {
  return axios.post(REQUEST_ACCOUNT_CONFIRM_URL, {
    phoneNumber, // This casing is inconsistent with the rest of the API, sorry
    code,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function getCurrentUser() {
  return axios
    .get<UserModel>(`${API_URL}/user/`, {})
    .then((res) => res.data)
    .then((response: any) => {
      return new UserModel(response.data)
    })
}

export function getCurrentClient() {
  return axios
    .get<Client>(`${API_URL}/client/`, {})
    .then((res) => res.data)
    .then((response: any) => {
      return new Client(response.data)
    })
}

export function getCurrentAgency() {
  return axios
    .get<Agency>(`${API_URL}/agency/`, {})
    .then((res) => res.data)
    .then((response: any) => {
      return new Agency(response.data)
    })
}

export function getClientByUniqueId(uniqueId: string) {
  return axios.get<Client>(`${API_URL}/clients/${uniqueId}/`, {})
}

export function enablePricing(clientUniqueId: string) {
  return axios.post<Client>(`${API_URL}/clients/${clientUniqueId}/pricing/enable/`, {})
}

export function disablePricing(clientUniqueId: string) {
  return axios.post<Client>(`${API_URL}/clients/${clientUniqueId}/pricing/disable/`, {})
}

export function upsertPricingResponse(
  clientUniqueId: string,
  defaultResponse: string,
  items: PricingResponseItem[]
) {
  return axios.post<Client>(`${API_URL}/clients/${clientUniqueId}/pricing/upsert/`, {
    default_response: defaultResponse,
    items,
  })
}

export function getWebsiteCode(clientUniqueId: string) {
  return axios.get<ClientCodeModel>(`${API_URL}/clients/${clientUniqueId}/code/`, {})
}

export function getClientAIResponses(clientUniqueId: string) {
  const response = axios.get<ClientAIResponsesModel[]>(
    `${API_URL}/clients/${clientUniqueId}/ai_responses/`,
    {}
  )
  return response
    .then((response: any) => response.data)
    .then((response: any) => response.data.map((item: any) => new ClientAIResponsesModel(item)))
}

export function updateClientAIResponses(clientUniqueId: string, data: any) {
  return axios.post<ClientAIResponsesModel>(
    `${API_URL}/clients/${clientUniqueId}/ai_responses/update/`,
    data
  )
}

export function enableZapier(clientUniqueId: string) {
  return axios.post<Client>(`${API_URL}/clients/${clientUniqueId}/zapier/enable/`, {})
}

export function disableZapier(clientUniqueId: string) {
  return axios.post<Client>(`${API_URL}/clients/${clientUniqueId}/zapier/disable/`, {})
}

export function enableSalesforce(clientUniqueId: string, goBackToPath: string) {
  return axios.post<EnableSalesforceResponse>(
    `${API_URL}/clients/${clientUniqueId}/salesforce/enable/?goBackToPath=${goBackToPath}`,
    {}
  )
}

export function disableSalesforce(clientUniqueId: string) {
  return axios.post<Client>(`${API_URL}/clients/${clientUniqueId}/salesforce/disable/`, {})
}

export function enableSlack(clientUniqueId: string) {
  return axios.post<Client>(`${API_URL}/clients/${clientUniqueId}/slack/enable/`, {})
}

export function disableSlack(clientUniqueId: string) {
  return axios.post<Client>(`${API_URL}/clients/${clientUniqueId}/slack/disable/`, {})
}

export function updateSlackWebhookUrl(clientUniqueId: string, updatedWebhookUrl: string) {
  return axios.post<Client>(`${API_URL}/clients/${clientUniqueId}/slack/update_webhook_url/`, {
    webhook_url: updatedWebhookUrl,
  })
}

export function archiveClient(clientUniqueId: string) {
  // Not using DELETE to keep URLs explicit
  return axios.post(`${API_URL}/clients/${clientUniqueId}/archive/`, {})
}

export function enableServiceFusion(clientUniqueId: string, goBackToPath: string) {
  return axios.post<EnableServiceFusionResponse>(
    `${API_URL}/clients/${clientUniqueId}/service_fusion/enable/?goBackToPath=${goBackToPath}`,
    {}
  )
}

export function disableServiceFusion(clientUniqueId: string) {
  return axios.post<Client>(`${API_URL}/clients/${clientUniqueId}/service_fusion/disable/`, {})
}
