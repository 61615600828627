/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'

import {PageTitle} from '../../../_metronic/layout/core'

import {useAuth} from '../../modules/auth'

import ConversationList from '../../modules/dashboard/components/ConversationList'
import AccountUsage from '../../modules/dashboard/components/AccountUsage'
import ConversationStatistics from '../../modules/dashboard/components/ConversationStatistics'
import {KTSVG} from '../../../_metronic/helpers'
import {useActiveConversation} from '../../modules/conversations/core/_hooks'
import {ChatInner, ChatInnerRefProps} from '../../../_metronic/partials'

const ConversationsPage: FC = () => {

  const {currentUser} = useAuth()
  const chatRef = useRef<ChatInnerRefProps>()

  const [lastRequestedStatsUpdate, setLastRequestedStatsUpdate] = useState<Date>(new Date())

  const {conversation} = useActiveConversation()

  return (
    <div className='row g-5' style={{height: '100%'}}>
      <div className='col-lg-4'>
        <div className='card card-custom card-stretch shadow mb-5'>
          <div className='card-header pt-7' id='kt_chat_contacts_header'>
            <form className='w-100 position-relative mb-5' autoComplete='off'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
              />

              <input
                type='text'
                className='form-control form-control-solid px-15'
                name='search'
                placeholder='Search for a conversation...'
              />
            </form>
          </div>
          <div className='card-body overflow-auto' style={{maxHeight: 'calc(100vh - 200px)'}}>
            <ConversationList isDashboard={false} />
          </div>
        </div>
        {false && (
          <div className='card card-custom card-stretch-50 shadow mb-5'>
            <div className='card-header'>
              <h3 className='card-title'>Account Usage</h3>
            </div>
            <div className='card-body overflow-auto'>
              <AccountUsage />
            </div>
            <div className='card-footer'>
              <div className='d-flex justify-content-center align-items-center'>
                <Link to='/account/usage' className='btn btn-light'>
                  View Full Usage
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='col-lg-8'>
        <div className='card card-custom card-stretch shadow'>
          <div className='card-header'>
            {conversation && (
              <div className='card-title'>
                <div className='symbol-group symbol-hover'></div>
                <div className='d-flex justify-content-center flex-column me-3'>
                  <a
                    href='#'
                    className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                  >
                    {conversation.getName()}
                  </a>

                  <div className='mb-0 lh-1'>
                    {conversation?.session.status === 'active' ? (
                      <>
                        <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                        <span className='fs-7 fw-bold text-gray-400'> User Connected</span>
                      </>
                    ) : (
                      <>
                        <span className='badge badge-danger badge-circle w-10px h-10px me-1'></span>
                        <span className='fs-7 fw-bold text-gray-400'> User Disconnected</span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className='card-toolbar'>
              {false && (
                <button type='button' className='btn btn-sm btn-light'>
                  Change Dates
                </button>
              )}
            </div>
          </div>
          <div className='card-body p-0' style={{maxHeight: 'calc(100vh - 200px)'}}>
            <ChatInner ref={chatRef} isDrawer={false} />
          </div>
        </div>
      </div>
    </div>
  )
}

const ConversationsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ALL_CONVERSATIONS'})}</PageTitle>
      <ConversationsPage />
    </>
  )
}

export {ConversationsWrapper}
