/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useRef, useCallback} from 'react'
import Identicon from 'react-identicons'
import moment from 'moment-timezone'
import ReconnectingWebSocket from 'reconnecting-websocket'

import {Conversation, RecentConversationsModel} from '../../conversations/core/_models'
import {getRecentConversations} from '../../conversations/core/_requests'

import ErrorContent from './ErrorContent'
import InfoContent from './InfoContent'
import {useAuth} from '../../auth'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'

type Props = {
  onSetLastRequestedStatsUpdate?: () => void
  isDashboard?: boolean
}

const RecentConversations: React.FC<Props> = ({
  onSetLastRequestedStatsUpdate,
  isDashboard = false,
}) => {
  const {auth, currentUser, setSelectedConversationSessionId} = useAuth()

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const [recentConversationsResponse, setRecentConversationsResponse] =
    useState<RecentConversationsModel | null>(null)

  const [chatWebsocket, setChatWebsocket] = useState<ReconnectingWebSocket | null>(null)
  const [websocketConnected, setWebsocketConnected] = useState(false)

  const recentConversationsRef = useRef<Conversation[]>([])
  const [lastServerInteraction, setLastServerInteraction] = useState<Date | null>(null)

  const loadRecentConversations = useCallback(async () => {
    setLoading(true)
    try {
      const usingClientId = auth?.selected_client_id
      const {data: recentConversations} = await getRecentConversations(isDashboard)
      setRecentConversationsResponse(recentConversations)
      recentConversationsRef.current = recentConversations.conversations
      setLastServerInteraction(new Date())
      setError(null)
      if (!isDashboard && recentConversations.conversations.length > 0) {
        console.log(
          'setSelectedConversationSessionId: ',
          recentConversations.conversations[0].session_id
        )
        setSelectedConversationSessionId(recentConversations.conversations[0].session_id)
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || 'Failed to retrieve recent conversations'
      setError(errorMessage)
    }
    setLoading(false)
  }, [isDashboard, setSelectedConversationSessionId, auth?.selected_client_id])

  useEffect(() => {
    const selectedClientId = auth?.selected_client_id
    const defaultClient = currentUser?.active_client_memberships[0]?.client

    if (!defaultClient || !auth?.api_token || (!defaultClient.client_id && !selectedClientId)) {
      return
    }

    const clientId = selectedClientId || defaultClient.client_id
    const appWsUrl = process.env.REACT_APP_WIDGET_WS_URL

    const websocketUrl = `${appWsUrl}/ws/chat/${clientId}/?token=${auth.api_token}`

    const websocket = new ReconnectingWebSocket(websocketUrl)
    setChatWebsocket(websocket)

    websocket.onopen = () => {
      setWebsocketConnected(true)
    }

    websocket.onclose = function (e) {
      setWebsocketConnected(false)
    }

    websocket.onerror = function (e) {
      // Log the error
      console.error(e)
      setWebsocketConnected(false)
    }

    websocket.onmessage = function (e) {
      // This is the generic client message handler
      const data = JSON.parse(e.data)
      if (data && data.type && data.data) {
        if (data.type === 'connected') {
          console.log('connected: ', data.data)
        } else if (data.type === 'chat_message') {
          console.log('chat_message: ', data.data)
        } else if (data.type === 'session_update') {
          if (onSetLastRequestedStatsUpdate) {
            // This re-retrieves and re-animates the dashboard charts, which maybe is ok because it lets the user know there's a new conversation to look at.
            onSetLastRequestedStatsUpdate()
          }
          const {session_id: updatedSessionId, session} = data.data
          let conversationHasBeenUpdated = false
          recentConversationsRef.current = recentConversationsRef.current?.map((conversation) => {
            if (conversation.session_id === updatedSessionId) {
              const updatedConversation = new Conversation({
                ...conversation,
                ...session,
              })
              conversationHasBeenUpdated = true
              return updatedConversation
            }
            return conversation
          })
          if (!conversationHasBeenUpdated) {
            recentConversationsRef.current = [
              new Conversation({
                ...session,
              }),
              ...recentConversationsRef.current,
            ]
          }
          setLastServerInteraction(new Date())
        } else if (data.type === 'presence_update') {
          // TODO: Update the presence list
        } else {
          console.warn('Unknown RecentConversations message type: ', data.type)
        }
      } else {
        console.warn('Unsupported message (500): ', data)
      }
    }

    return () => {
      websocket.close()
    }
  }, [
    currentUser?.active_client_memberships,
    auth?.api_token,
    recentConversationsRef,
    onSetLastRequestedStatsUpdate,
    auth?.selected_client_id,
  ])

  useEffect(() => {
    loadRecentConversations()
  }, [loadRecentConversations])

  if (loading) {
    return <></>
  }

  // We use the last 7 messages because I can't figure out how to get things to scroll and fit correctly with 10

  return (
    <>
      {error ? (
        <ErrorContent title='Error' subtitle={error} />
      ) : (
        <>
          {recentConversationsRef.current.length === 0 ? (
            <InfoContent title='No Conversations' subtitle='No conversations have been started' />
          ) : (
            <>
              {recentConversationsRef.current.map((conversation) => {
                const user = conversation.started_by_frontend_user
                let elementId = `recent-conversation-${conversation.session_id}`
                if (isDashboard) {
                  elementId = 'kt_drawer_chat_toggle'
                }
                return (
                  <div
                    className='d-flex align-items-center mb-7'
                    style={{cursor: 'pointer'}}
                    id={elementId}
                    key={conversation.session_id}
                    onClick={() => {
                      setSelectedConversationSessionId(conversation.session_id)
                    }}
                  >
                    {/* begin::Avatar */}
                    <div className='symbol symbol-50px me-5'>
                      <div className='symbol symbol-45px symbol-circle'>
                        {user && user.avatar_url ? (
                          <img alt='Avatar' src={user.avatar_url} />
                        ) : (
                          <Identicon string={user.anonymous_mnemonic_name || 'user'} size={45} />
                        )}
                      </div>
                    </div>
                    {/* end::Avatar */}
                    {/* begin::Text */}
                    <div className='flex-grow-1'>
                      <div className='text-dark fw-bolder text-hover-primary fs-6'>
                        {`${user.name ? user.name : `User ${user.anonymous_mnemonic_name}`}${
                          conversation.status === 'active' ? ' (Active)' : ''
                        }`}
                      </div>
                      <span className='text-muted d-block fw-bold'>
                        {`${conversation.message_count} ${
                          conversation.message_count === 1 ? 'message' : 'messages'
                        } • ${moment(conversation.date_last_active).fromNow()}`}
                      </span>
                      {(conversation?.known_email_addresses &&
                        conversation?.known_email_addresses?.length > 0) ||
                      (conversation?.known_phone_numbers &&
                        conversation.known_phone_numbers.length > 0) ? (
                        <div className='d-flex flex-wrap fw-bold fs-6 pe-2'>
                          {conversation?.known_email_addresses &&
                          conversation?.known_email_addresses?.length > 0 ? (
                            <>
                              <span
                                className='d-flex align-items-center'
                                style={{color: '#009ef7'}}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/communication/com011.svg'
                                  className='svg-icon-4 me-1'
                                />
                                {`${conversation?.known_email_addresses?.length} email address${
                                  conversation?.known_email_addresses?.length === 1 ? '' : 'es'
                                }`}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                          {conversation?.known_phone_numbers &&
                          conversation?.known_phone_numbers?.length > 0 ? (
                            <>
                              <span
                                className='d-flex align-items-center'
                                style={{
                                  color: '#009ef7',
                                  marginLeft:
                                    conversation?.known_email_addresses &&
                                    conversation?.known_email_addresses?.length > 0
                                      ? 5
                                      : 0,
                                }}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/communication/phone.svg'
                                  className='svg-icon-4 me-1'
                                />
                                {`${conversation?.known_phone_numbers?.length} phone${
                                  conversation?.known_phone_numbers?.length === 1 ? '' : 's'
                                }`}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* end::Text */}
                  </div>
                )
              })}
            </>
          )}
        </>
      )}
    </>
  )
}

export default RecentConversations
