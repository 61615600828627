import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
  title: string
  subtitle: string
  fullWidth?: boolean
}

const InfoContent: React.FC<Props> = ({title, subtitle, fullWidth = true}) => {
  // Return a full height div with centered content
  return (
    <div className='d-flex flex-column align-items-center justify-content-center h-100'>
      <div className={`alert alert-info d-flex align-items-center p-5${fullWidth ? ' w-100' : ''}`}>
        <span className='svg-icon svg-icon-2hx svg-icon-info me-3'>
          <KTSVG
            path='/media/icons/duotune/general/gen045.svg'
            className='svg-icon svg-icon-2hx svg-icon-dark me-4'
          />
        </span>
        <div className='d-flex flex-column'>
          <h5 className='mb-1'>{title}</h5>
          <span>{subtitle}</span>
        </div>
      </div>
    </div>
  )
}

export default InfoContent
