class ChatbotLastName {
  last_name: string

  constructor(data: any) {
    this.last_name = data.last_name
  }
}

export class ClientChatbot {
  unique_identifier: string
  first_name: string
  last_name: ChatbotLastName
  pronoun: string
  possessive_pronoun: string
  email_address: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.first_name = data.first_name
    this.last_name = new ChatbotLastName(data.last_name)
    this.pronoun = data.pronoun
    this.possessive_pronoun = data.possessive_pronoun
    this.email_address = data.email_address
  }

  getName() {
    return `${this.first_name} ${this.last_name.last_name}`
  }
}

export class InboundEmail {
  unique_identifier: string
  date: string
  from_email_address: string
  formatted_from_email_address: string
  subject: string
  body: string
  html_body: string
  message_id: string
  to_emails: string
  cc_emails: string
  bcc_emails: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.date = data.date
    this.from_email_address = data.from_email_address
    this.formatted_from_email_address = data.formatted_from_email_address
    this.subject = data.subject
    this.body = data.body
    this.html_body = data.html_body
    this.message_id = data.message_id
    this.to_emails = data.to_emails
    this.cc_emails = data.cc_emails
    this.bcc_emails = data.bcc_emails
  }
}

export class GetClientEmailsModel {
  client_chatbot: ClientChatbot
  emails: InboundEmail[]

  constructor(data: any) {
    this.client_chatbot = new ClientChatbot(data.client_chatbot)
    this.emails = data.emails.map((email: any) => new InboundEmail(email))
  }
}
