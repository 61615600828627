/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import moment from 'moment-timezone'

import {toAbsoluteUrl} from '../../../../_metronic/helpers'

import {ConversationStatisticsModel} from '../../conversations/core/_models'
import {getConversationStatistics} from '../../conversations/core/_requests'

import ErrorContent from './ErrorContent'
import InfoContent from './InfoContent'
import {ChartsWidget1} from '../../../../_metronic/partials/widgets'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {useCallback} from 'react'
import {useAuth} from '../../auth'

type Props = {
  lastRequestedStatsUpdate: Date
}

function getChartOptions(height: number, data: number[], labels: string[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: 'Conversations',
        data: data,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: labels,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (val: number) {
          return val.toFixed(0)
        },
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return `${val} ${val === 1 ? 'conversation' : 'conversations'}`
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

function ConversationStatisticsChart({response}: {response: ConversationStatisticsModel}) {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const data = response.last_seven_day_strings.map((item) => {
      return response.conversation_counts_by_date[item] || 0
    })
    const labels = response.last_seven_day_strings.map((item) => {
      return moment(item, 'YYYY-MM-DD').format('MMM D')
    })

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, data, labels))
    if (chart) {
      chart.render()
    } else {
      console.warn('Failed to')
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, response.conversation_counts_by_date, response.last_seven_day_strings])

  return <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '100%'}} />
}

const RecentConversations: React.FC<Props> = ({lastRequestedStatsUpdate}) => {
  const {auth} = useAuth()

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const [recentConversationsResponse, setConversationStatisticsResponse] =
    useState<ConversationStatisticsModel | null>(null)

  const loadStats = useCallback(async () => {
    console.log('lastRequestedStatsUpdate: ', lastRequestedStatsUpdate)
    if (auth?.selected_client_id) {
      // What's the correct way to have a dependency that does nothing but is needed to trigger a re-render?
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // ^ well yes but I don't want to use an eslint disable.
      // I'm talking to a robot smh -CC
    }
    setLoading(true)
    try {
      const {data: updatedStatistics} = await getConversationStatistics()
      setConversationStatisticsResponse(updatedStatistics)
      setError(null)
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.error || 'Failed to retrieve conversation statistics'
      setError(errorMessage)
    }
    setLoading(false)
  }, [lastRequestedStatsUpdate, auth?.selected_client_id])

  useEffect(() => {
    loadStats()
  }, [loadStats])

  if (loading) {
    return <></>
  }

  return (
    <>
      {error ? (
        <div className='row'>
          <div className='col-lg-12 p-10'>
            <ErrorContent title='Error' subtitle={error} />
          </div>
        </div>
      ) : (
        <>
          {!recentConversationsResponse ||
          Object.keys(recentConversationsResponse?.conversation_counts_by_date || {}).length ===
            0 ? (
            <div className='row'>
              <div className='col-lg-12 p-10'>
                <InfoContent
                  title='No Conversations'
                  subtitle='No conversation statistics are available'
                />
              </div>
            </div>
          ) : (
            <>
              <ConversationStatisticsChart response={recentConversationsResponse} />
            </>
          )}
        </>
      )}
    </>
  )
}

export default RecentConversations
