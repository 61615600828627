/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import {useAuth} from '../../auth'
import ClientCodeBlock from '../../apps/clients/ClientCodeBlock'

type Props = {}

const GettingStarted: React.FC<Props> = () => {
  const {currentClient} = useAuth()

  if (!currentClient) {
    return <></>
  }

  return (
    <div className='row g-5' style={{height: '100%'}}>
      <div className='col-lg-12'>
        <div className='card card-custom shadow mb-5'>
          <div className='card-header py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Getting Started</span>
              <span className='text-muted fw-bold fs-7'>
                Follow the instructions below to get started
              </span>
            </h3>
            <div className='card-toolbar'></div>
          </div>
          <div className='card-body'>
            <ClientCodeBlock client={currentClient} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GettingStarted
