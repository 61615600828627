import {filterProps} from '@formatjs/intl'
import React from 'react'
import {UserModel} from '../../auth'

export interface FrontendUser {
  unique_identifier: string
  anonymous_mnemonic_name: string
  avatar_url?: string
  name?: string
}

export interface AuthUser {
  unique_identifier: string
  name?: string
  first_name?: string
  last_name?: string
  avatar_url?: string
}

export interface SystemUser {
  unique_identifier: string
  name?: string
}

class SimpleFromClient {
  name: string
  avatar_url: string

  constructor(data: any) {
    this.name = data.name
    this.avatar_url = data.avatar_url
  }
}

export class MatchingClassificationCategory {
  unique_identifier: string
  category_id: number // int
  name: string // e.g 'Yes (Simple Affirmative)

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.category_id = data.category_id
    this.name = data.name
  }
}

export class Message {
  created_at: string
  text: string
  unique_identifier: string
  // TODO: Fix these (old references)
  type: string
  template: string
  time: string
  user: number
  // One of the "from_" user types will be set and we keep them separate to be explicit
  from_frontend_user?: FrontendUser
  from_authenticated_user?: AuthUser
  from_system_user?: SystemUser
  from_client?: SimpleFromClient | null

  matching_classification_category?: MatchingClassificationCategory
  matching_classification_category_percentage_likelihood?: number

  constructor(message: any) {
    this.created_at = message.created_at
    this.text = message.text
    this.unique_identifier = message.unique_identifier
    this.type = message.type
    this.template = message.template
    this.time = message.time
    this.user = message.user
    this.from_frontend_user = message.from_frontend_user
    this.from_authenticated_user = message.from_authenticated_user
    this.from_system_user = message.from_system_user
    this.from_client = message.from_client ? new SimpleFromClient(message.from_client) : null
    this.matching_classification_category = message.matching_classification_category
      ? new MatchingClassificationCategory(message.matching_classification_category)
      : undefined
    this.matching_classification_category_percentage_likelihood =
      message.matching_classification_category_percentage_likelihood
  }

  isFromAuthUser(user: UserModel | undefined): boolean {
    if (
      !user ||
      !this.from_authenticated_user ||
      user.unique_identifier !== this.from_authenticated_user.unique_identifier
    ) {
      return false
    }
    return true
  }

  getFromName(currentUser: UserModel | undefined): string {
    if (this.isFromAuthUser(currentUser)) {
      return 'You'
    } else if (this.from_frontend_user) {
      return this.from_frontend_user.name
        ? this.from_frontend_user.name
        : `User (No Name Given Yet)`
    } else if (this.from_system_user) {
      return this.from_system_user.name || 'Morto AI'
    }
    return ''
  }

  getFromAvatar(currentUser: UserModel | undefined): string {
    let defaultAvatar = 'https://static.morto.ai/v1/img/default_avatar.png'
    if (this.from_client) {
      return this.from_client.avatar_url || defaultAvatar
    }
    if (currentUser && this.isFromAuthUser(currentUser)) {
      return currentUser.avatar_url || defaultAvatar
    }
    return defaultAvatar
  }
}

class KnownEmailAddress {
  email_address: string

  constructor(data: any) {
    this.email_address = data.email_address
  }
}

class KnownPhoneNumber {
  phone_number: string

  constructor(data: any) {
    this.phone_number = data.phone_number
  }
}

class SalesforceLead {
  salesforce_id: string
  salesforce_url_to_lead?: string

  constructor(data: any) {
    this.salesforce_id = data.salesforce_id
    this.salesforce_url_to_lead = data.salesforce_url_to_lead
  }
}

export class Conversation {
  created_at: string
  updated_at: string
  session_id: string
  status: string
  date_last_message: string
  date_last_active: string
  started_by_frontend_user: FrontendUser
  started_by_auth_user?: AuthUser
  auth_user_connected?: AuthUser
  remote_ip_address: string
  message_count: number
  total_estimated_token_count: number
  latest_messages?: Message[]
  known_email_addresses?: KnownEmailAddress[]
  known_phone_numbers?: KnownPhoneNumber[]
  salesforce_lead?: SalesforceLead | null

  constructor(conversation: any) {
    this.created_at = conversation.created_at
    this.updated_at = conversation.updated_at
    this.session_id = conversation.session_id
    this.status = conversation.status
    this.date_last_message = conversation.date_last_message
    this.date_last_active = conversation.date_last_active
    this.started_by_frontend_user = conversation.started_by_frontend_user
    this.started_by_auth_user = conversation.started_by_auth_user
    this.auth_user_connected = conversation.auth_user_connected
    this.remote_ip_address = conversation.remote_ip_address
    this.message_count = conversation.message_count
    this.total_estimated_token_count = conversation.total_estimated_token_count
    this.latest_messages = (conversation.latest_messages || []).map((x: any) => new Message(x))
    this.known_email_addresses = (conversation.known_email_addresses || []).map(
      (x: any) => new KnownEmailAddress(x)
    )
    this.known_phone_numbers = (conversation.known_phone_numbers || []).map(
      (x: any) => new KnownPhoneNumber(x)
    )
    this.salesforce_lead = conversation.salesforce_lead
      ? new SalesforceLead(conversation.salesforce_lead)
      : null
  }
}

export interface RecentConversationsModel {
  conversations: Conversation[]
}

export interface AccountUsageModel {}

export interface ConversationStatisticsModel {
  conversation_counts_by_date: {[key: string]: number}
  last_seven_day_strings: string[]
}

export class IndividualConversationModel {
  session: Conversation

  constructor(conversation: any) {
    this.session = new Conversation(conversation.session)
  }

  getName(): string {
    if (this.session.started_by_frontend_user) {
      if (this.session.started_by_frontend_user.name) {
        return this.session.started_by_frontend_user.name
      }
      if (this.session.started_by_frontend_user.anonymous_mnemonic_name) {
        return `User ${this.session.started_by_frontend_user.anonymous_mnemonic_name}`
      }
      return 'User (No Name Given Yet)'
    }
    return 'Conversation'
  }
}
