import axios from 'axios'
import {GetDirectMailModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const CLIENT_DIRECT_MAIL_URL = `${API_URL}/client/direct_mail/`

// Server should return GetDirectMailModel
export function getDirectMailItems() {
  return axios.get<GetDirectMailModel>(CLIENT_DIRECT_MAIL_URL)
}
