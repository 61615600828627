import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {IntermediateLayout} from '../../_metronic/layout/IntermediateLayout'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {GetStartedWrapper} from '../modules/get_started/GetStartedWrapper'
import {DirectMailWrapper} from '../modules/direct_mail/DirectMailWrapper'
import {EmailsWrapper} from '../modules/emails/EmailsWrapper'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {ConversationsWrapper} from '../pages/conversations/ConversationWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const {auth} = useAuth()

  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const AgencyAccountPage = lazy(() => import('../modules/accounts/AgencyAccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ConversationsPage = lazy(() => import('../modules/apps/conversations/ConversationsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const LeadsPage = lazy(() => import('../modules/apps/leads/LeadsPage'))
  const ClientsPage = lazy(() => import('../modules/apps/clients/ClientsPage'))

  const SelectViewPage = lazy(() => import('../modules/apps/select_view/SelectViewPage'))

  if (!auth?.selected_view_type) {
    return (
      <Routes>
        <Route element={<IntermediateLayout />}>
          {/* Redirect to Dashboard after success login/registration */}
          <Route path='select/*' element={<SelectViewPage />} />
          <Route path='*' element={<Navigate to='/select' />} />
        </Route>
      </Routes>
    )
  }

  if (auth.selected_view_type === 'agency') {
    return (
      <Routes>
        <Route element={<MasterLayout />}>
          <Route
            path='clients/*'
            element={
              <SuspensedView>
                <ClientsPage />
              </SuspensedView>
            }
          />
          <Route
            path='account/*'
            element={
              <SuspensedView>
                <AgencyAccountPage />
              </SuspensedView>
            }
          />
          <Route path='*' element={<Navigate to='/clients' />} />
        </Route>
      </Routes>
    )
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='get_started' element={<GetStartedWrapper />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='conversations' element={<ConversationsWrapper />} />
        <Route
          path='conversations_old/*'
          element={
            <SuspensedView>
              <ConversationsPage />
            </SuspensedView>
          }
        />
        <Route path='direct_mail' element={<DirectMailWrapper />} />
        <Route path='emails' element={<EmailsWrapper />} />
        <Route
          path='leads/*'
          element={
            <SuspensedView>
              <LeadsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        {false && <Route path='*' element={<Navigate to='/error/404' />} />}
        <Route path='*' element={<Navigate to='/dashboard' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
