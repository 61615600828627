import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {Agency, AuthModel, Client, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getCurrentAgency, getCurrentClient, getUserByToken} from './_requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  currentClient: Client | undefined
  currentAgency: Agency | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  setCurrentClient: Dispatch<SetStateAction<Client | undefined>>
  logout: () => void
  setSelectedClientClientId: (clientId?: string) => void
  setSelectedAgencyUniqueId: (agencyUniqueId?: string) => void
  selectedConversationSessionId: string | undefined
  setSelectedConversationSessionId: Dispatch<SetStateAction<string | undefined>>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  currentClient: undefined,
  currentAgency: undefined,
  setCurrentUser: () => {},
  setCurrentClient: () => {},
  logout: () => {},
  setSelectedClientClientId: () => {},
  setSelectedAgencyUniqueId: () => {},
  selectedConversationSessionId: undefined,
  setSelectedConversationSessionId: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentClient, setCurrentClient] = useState<Client | undefined>()
  const [currentAgency, setCurrentAgency] = useState<Agency | undefined>()

  const [selectedConversationSessionId, setSelectedConversationSessionId] = useState<
    string | undefined
  >()

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  function setSelectedClientClientId(clientId?: string) {
    if (auth) {
      const updatedAuth = {
        ...auth,
        selected_client_id: clientId,
        selected_view_type: 'client',
      } as AuthModel
      setAuth(updatedAuth)
      authHelper.setAuth(updatedAuth)
    }
  }

  function setSelectedAgencyUniqueId(agencyUniqueId?: string) {
    if (auth) {
      const updatedAuth = {
        ...auth,
        selected_agency_unique_id: agencyUniqueId,
        selected_view_type: 'agency',
      } as AuthModel
      setAuth(updatedAuth)
      authHelper.setAuth(updatedAuth)
      console.log('updated auth', updatedAuth)
    } else {
      console.warn('Auth was not set')
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setCurrentClient(undefined)
  }

  async function loadCurrentClient() {
    const updatedClient = await getCurrentClient()
    setCurrentClient(updatedClient)
  }

  async function loadCurrentAgency() {
    const updatedAgency = await getCurrentAgency()
    setCurrentAgency(updatedAgency)
  }

  useEffect(() => {
    if (auth?.api_token && auth?.selected_view_type === 'client') {
      loadCurrentClient()
    }
  }, [auth?.api_token, auth?.selected_client_id, auth?.selected_view_type])

  useEffect(() => {
    if (auth?.api_token && auth?.selected_view_type === 'agency') {
      loadCurrentAgency()
    }
  }, [auth?.api_token, auth?.selected_agency_unique_id, auth?.selected_view_type])

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        currentClient,
        currentAgency,
        setCurrentUser,
        setCurrentClient,
        logout,
        selectedConversationSessionId,
        setSelectedConversationSessionId,
        setSelectedClientClientId,
        setSelectedAgencyUniqueId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            console.log('AuthInit data: ', data)
            setCurrentUser(new UserModel(data))
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
