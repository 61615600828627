import axios from 'axios'
import {GetClientEmailsModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const CLIENT_EMAILS_URL = `${API_URL}/client/emails/`

// Server should return GetDirectMailModel
export function getClientEmails() {
  return axios.get<GetClientEmailsModel>(CLIENT_EMAILS_URL)
}
