/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'

import {
  getUserByToken,
  loginWithPhone,
  loginWithPhoneConfirm,
  requestAccountResendCode,
} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {UserModel} from '../core/_models'

const loginWithPhoneSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Phone number is required'),
})

const loginConfirmPhoneSchema = Yup.object().shape({
  code: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Login code is required'),
})

const initialLoginFormValues = {
  phoneNumber: '',
}

const initialLoginConfirmFormValues = {
  code: '',
}

function LoginConfirm({phoneNumber, onCancel}: {phoneNumber: string; onCancel: () => void}) {
  const [loading, setLoading] = useState(false)
  const [resendingCode, setResendingCode] = useState(false)

  const {saveAuth, setCurrentUser} = useAuth()

  async function onResendCode() {
    setResendingCode(true)
    try {
      await requestAccountResendCode(phoneNumber)
    } catch (error: any) {
      console.warn('Failed to resend code: ', error)
    }
    setResendingCode(false)
  }

  const formik = useFormik({
    initialValues: initialLoginConfirmFormValues,
    validationSchema: loginConfirmPhoneSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await loginWithPhoneConfirm(phoneNumber, values.code)
        const {data: user} = await getUserByToken(auth.api_token)
        const userModel = new UserModel(user)
        setCurrentUser(userModel)
        let selectedView: 'client' | 'agency' | undefined = undefined
        let selectedClientId: string | undefined = undefined
        let selectedAgencyUniqueId: string | undefined = undefined
        if (
          userModel.active_client_memberships.length > 0 &&
          userModel.active_agency_memberships.length === 0
        ) {
          selectedView = 'client'
          selectedClientId = userModel.active_client_memberships[0].client.client_id
        } else if (
          userModel.active_client_memberships.length === 0 &&
          userModel.active_agency_memberships.length > 0
        ) {
          selectedView = 'agency'
          selectedAgencyUniqueId = userModel.active_agency_memberships[0].agency.unique_identifier
        }
        saveAuth({
          ...auth,
          selected_view_type: selectedView,
          selected_client_id: selectedClientId,
          selected_agency_unique_id: selectedAgencyUniqueId,
        })
      } catch (error: any) {
        const errorMessage = error?.response?.data?.error || 'The code you entered is invalid'
        console.error(error)
        saveAuth(undefined)
        setStatus(errorMessage)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>We Texted You a Code</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          Enter the code below to login to your account
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger text-center'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Verification Code</label>
        <input
          placeholder='Enter the code'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.code && formik.errors.code},
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          type='text'
          name='code'
          autoComplete='off'
          autoFocus
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.code}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-lg btn-light-primary w-100 mb-5'
          onClick={onCancel}
        >
          Cancel
        </button>
        <button type='button' className='btn btn-lg btn-light w-100 mb-5' onClick={onResendCode}>
          {!resendingCode && <span className='indicator-label'>Resend Code</span>}
          {resendingCode && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}

export function LoginWithPhone() {
  const [loading, setLoading] = useState(false)
  const [confirmingPhone, setConfirmingPhone] = useState<string | null>(null)

  const formik = useFormik({
    initialValues: initialLoginFormValues,
    validationSchema: loginWithPhoneSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        // Login with phone is a two-step process (requires an active account and a code to be verified)
        await loginWithPhone(values.phoneNumber)
        setConfirmingPhone(values.phoneNumber)
      } catch (error: any) {
        const errorMessage = error?.response?.data?.error || 'Login information is invalid'
        console.error(error)
        setStatus(errorMessage)
      } finally {
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  if (confirmingPhone) {
    return <LoginConfirm phoneNumber={confirmingPhone} onCancel={() => setConfirmingPhone(null)} />
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to Morto</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          Need an account?{' '}
          <Link to='/auth/request' className='link-primary fw-bolder'>
            Request an Account
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger text-center'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Phone Number</label>
        <input
          placeholder='Enter your phone number'
          {...formik.getFieldProps('phoneNumber')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber},
            {
              'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
            }
          )}
          type='text'
          name='phoneNumber'
          autoFocus
        />
        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.phoneNumber}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
