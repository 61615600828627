/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import Identicon from 'react-identicons'

import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'

// Get the package.json version

const packageJson = require('../../../../../package.json')

interface HeaderUserMenuProps {
  simplified?: boolean
}

const HeaderUserMenu = ({simplified = false}: HeaderUserMenuProps) => {
  const {auth, currentUser, logout, setSelectedAgencyUniqueId, setSelectedClientClientId} =
    useAuth()

  if (!currentUser) {
    return <></>
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {currentUser.avatar_url ? (
              <img src={currentUser.avatar_url} alt='Avatar' />
            ) : (
              <Identicon string={currentUser.email} size={50} />
            )}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser.getName()}
              {false && 'badge' && (
                <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
              )}
            </div>
            <span className='fw-bold text-muted text-hover-primary fs-7'>{currentUser?.email}</span>
          </div>
        </div>
      </div>

      {false && (
        <>
          <div className='separator my-2'></div>

          <div className='menu-item px-5'>
            <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
              My Profile
            </Link>
          </div>

          <div className='menu-item px-5'>
            <a href='#' className='menu-link px-5'>
              <span className='menu-text'>My Projects</span>
              <span className='menu-badge'>
                <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
              </span>
            </a>
          </div>

          <div
            className='menu-item px-5'
            data-kt-menu-trigger='hover'
            data-kt-menu-placement='left-start'
            data-kt-menu-flip='bottom'
          >
            <a href='#' className='menu-link px-5'>
              <span className='menu-title'>My Subscription</span>
              <span className='menu-arrow'></span>
            </a>

            <div className='menu-sub menu-sub-dropdown w-175px py-4'>
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5'>
                  Referrals
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5'>
                  Billing
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-5'>
                  Payments
                </a>
              </div>

              <div className='menu-item px-3'>
                <a href='#' className='menu-link d-flex flex-stack px-5'>
                  Statements
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='View your statements'
                  ></i>
                </a>
              </div>

              <div className='separator my-2'></div>

              <div className='menu-item px-3'>
                <div className='menu-content px-3'>
                  <label className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input w-30px h-20px'
                      type='checkbox'
                      value='1'
                      defaultChecked={true}
                      name='notifications'
                    />
                    <span className='form-check-label text-muted fs-7'>Notifications</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className='menu-item px-5'>
            <a href='#' className='menu-link px-5'>
              My Statements
            </a>
          </div>
        </>
      )}

      <div className='separator my-2'></div>

      {false && <Languages />}

      {!simplified && auth?.selected_view_type === 'client' && (
        <div className='menu-item px-5 my-1'>
          <Link to='/account' className='menu-link px-5'>
            Account Overview
          </Link>
        </div>
      )}

      {auth?.selected_view_type &&
        currentUser.active_client_memberships.length > 0 &&
        currentUser.active_agency_memberships.length > 0 && (
          <>
            <div className='menu-item px-5 my-1'>
              <a
                onClick={() => {
                  if (auth?.selected_view_type === 'agency') {
                    // First we try to see if there's a client for the currently selected agency.
                    // This makes the transition into the customer view more relevant
                    let selectClientMembership = currentUser.active_client_memberships.find(
                      (clientMembership) =>
                        clientMembership.client.agency?.unique_identifier ===
                        auth.selected_agency_unique_id
                    )
                    if (!selectClientMembership) {
                      // If there's no client for the currently selected agency, we'll just select the first client
                      selectClientMembership = currentUser.active_client_memberships[0]
                    }
                    setSelectedClientClientId(selectClientMembership.client.client_id)
                  } else {
                    setSelectedAgencyUniqueId(
                      currentUser.active_agency_memberships[0].agency.unique_identifier
                    )
                  }
                }}
                className='menu-link px-5'
              >
                {auth?.selected_view_type === 'agency' ? (
                  'Switch to Customer View'
                ) : (
                  <>
                    {currentUser.active_agency_memberships.length === 1
                      ? `Switch to ${currentUser.active_agency_memberships[0].agency.getName()} View`
                      : 'Switch to Operator View'}
                  </>
                )}
              </a>
            </div>
          </>
        )}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>

      {packageJson.version && (
        <span
          className='text-muted'
          style={{position: 'absolute', right: 10, top: 10, fontSize: 11}}
        >
          {packageJson.version}
        </span>
      )}
    </div>
  )
}

export {HeaderUserMenu}
