/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import moment from 'moment-timezone'
import clsx from 'clsx'

import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'

import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

import {useAuth} from '../../modules/auth'

import ConversationList from '../../modules/dashboard/components/ConversationList'
import AccountUsage from '../../modules/dashboard/components/AccountUsage'
import ConversationStatistics from '../../modules/dashboard/components/ConversationStatistics'

const DashboardPage: FC = () => {
  const isDrawer = false

  const {currentUser} = useAuth()

  const now = moment()
  const sixDaysAgo = moment().subtract(6, 'days')

  const [lastRequestedStatsUpdate, setLastRequestedStatsUpdate] = useState<Date>(new Date())

  function onSetLastRequestedStatsUpdate() {
    // This allows the statistics component to re-retrieve data from the backend.
    // This likely should be somewhere else, but this is the easiest place for now.
    setLastRequestedStatsUpdate(new Date())
  }

  return (
    <div className='row g-5' style={{height: '100%'}}>
      <div className='col-lg-4'>
        <div className='card card-custom card-stretch shadow mb-5'>
          <div className='card-header'>
            <h3 className='card-title'>Recent Conversations</h3>
          </div>
          <div className='card-body overflow-auto' style={{maxHeight: '65vh'}}>
            <ConversationList
              isDashboard
              onSetLastRequestedStatsUpdate={onSetLastRequestedStatsUpdate}
            />
          </div>
          <div className='card-footer'>
            <div className='d-flex justify-content-center align-items-center'>
              <Link to='/conversations' className='btn btn-light'>
                View All Conversations
              </Link>
            </div>
          </div>
        </div>
        {false && (
          <div className='card card-custom card-stretch-50 shadow mb-5'>
            <div className='card-header'>
              <h3 className='card-title'>Account Usage</h3>
            </div>
            <div className='card-body overflow-auto'>
              <AccountUsage />
            </div>
            <div className='card-footer'>
              <div className='d-flex justify-content-center align-items-center'>
                <Link to='/account/usage' className='btn btn-light'>
                  View Full Usage
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='col-lg-8'>
        <div className='card card-custom card-stretch shadow'>
          <div className='card-header'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Conversation Statistics</span>
              <span className='text-muted fw-bold fs-7'>
                {sixDaysAgo.format('MMMM Do, YYYY')} - {now.format('MMMM Do, YYYY')}
              </span>
            </h3>
            <div className='card-toolbar'>
              <button type='button' className='btn btn-sm btn-light'>
                Change Dates
              </button>
            </div>
          </div>
          <div className='card-body p-0'>
            <ConversationStatistics lastRequestedStatsUpdate={lastRequestedStatsUpdate} />
          </div>
        </div>
      </div>
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
