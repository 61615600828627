/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {useActiveConversation} from '../../../../app/modules/conversations/core/_hooks'
import {EventHandlerUtil} from '../../../assets/ts/_utils'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {ChatInner, ChatInnerRefProps} from '../../chat/ChatInner'

const DrawerMessenger: FC = () => {
  const {currentUser, setSelectedConversationSessionId, selectedConversationSessionId} = useAuth()
  const drawerRef = useRef<HTMLDivElement>(null)
  const chatRef = useRef<ChatInnerRefProps>()
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const [message, setMessage] = useState<string>('')

  const {conversation, setConversation} = useActiveConversation()

  const sendMessage = () => {
    if (!message || !message.trim()) {
      return
    }
    if (chatRef.current) {
      chatRef.current.onSendMessage(message)
      setMessage('')
      textareaRef.current?.focus()
    }
  }

  const connectToChat = () => {
    if (chatRef.current) {
      chatRef.current.connectToSession()
    }
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && (e.ctrlKey || e.metaKey || e.shiftKey || e.altKey)) {
      // Add a new line
      e.preventDefault()
      setMessage(message + '\n')
    } else if (e.keyCode === 13) {
      e.preventDefault()
      sendMessage()
    }
  }

  const onDrawerClosed = useCallback(() => {
    setSelectedConversationSessionId(undefined)
  }, [setSelectedConversationSessionId])

  useEffect(() => {
    if (!drawerRef.current) {
      return
    }
    EventHandlerUtil.on(drawerRef.current, 'kt.drawer.after.hidden', (event: any) => {
      // setSelectedConversationSessionId needs to be called somewhere after the drawer is hidden, but
      // if it's here then this executes on a page load and breaks the Conversations page.
      // setSelectedConversationSessionId(undefined)
      setMessage('')
      if (selectedConversationSessionId) {
        onDrawerClosed()
      }
    })
  }, [drawerRef, setSelectedConversationSessionId, selectedConversationSessionId, onDrawerClosed])

  return (
    <div
      ref={drawerRef}
      id='kt_drawer_chat'
      className='bg-white'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
        <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <a
                href='#'
                className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
              >
                {conversation ? conversation.getName() : ' '}
              </a>
              <div className='mb-0 lh-1'>
                {conversation?.session.status === 'active' ? (
                  <>
                    <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                    <span className='fs-7 fw-bold text-gray-400'> User Connected</span>
                  </>
                ) : (
                  <>
                    <span className='badge badge-danger badge-circle w-10px h-10px me-1'></span>
                    <span className='fs-7 fw-bold text-gray-400'> User Disconnected</span>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            {false && (
              <div className='me-2'>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-three-dots fs-3'></i>
                </button>
              </div>
            )}
            <div
              className='btn btn-sm btn-icon btn-active-light-primary'
              id='kt_drawer_chat_close'
              onClick={() => onDrawerClosed()}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </div>
          </div>
        </div>
        <ChatInner ref={chatRef} setConversation={setConversation} />
        <div className='card-footer p-0'>
          <div
            style={{
              borderBottom: '1px solid #e5e5e5',
              padding: 10,
              position: 'relative',
            }}
          >
            <div className='input-group input-group-sm p-2'>
              <span style={{marginRight: 10}}>Phone Numbers:</span>
              <span>
                {conversation?.session?.known_phone_numbers &&
                conversation?.session?.known_phone_numbers?.length > 0 ? (
                  conversation?.session?.known_phone_numbers?.map((phoneNumber, index) => (
                    <span key={`phone-${index}`} className='badge badge-pill badge-primary mr-1'>
                      <a
                        href={`tel:${phoneNumber.phone_number}`}
                        target='_blank'
                        rel='noreferrer'
                        style={{color: 'white'}}
                      >
                        {phoneNumber.phone_number}
                      </a>
                    </span>
                  ))
                ) : (
                  <>
                    <span className=''>No Phone Numbers</span>
                  </>
                )}
              </span>
            </div>
            <div className='input-group input-group-sm p-2'>
              <span style={{marginRight: 10}}>Email Addresses:</span>
              <span>
                {conversation?.session?.known_email_addresses &&
                conversation?.session?.known_email_addresses?.length > 0 ? (
                  conversation?.session?.known_email_addresses?.map((emailAddress, index) => (
                    <span key={`email-${index}`} className='badge badge-pill badge-primary mr-1'>
                      <a
                        href={`mailto:${emailAddress.email_address}`}
                        target='_blank'
                        rel='noreferrer'
                        style={{color: 'white'}}
                      >
                        {emailAddress.email_address}
                      </a>
                    </span>
                  ))
                ) : (
                  <>
                    <span className=''>No Email Addresses</span>
                  </>
                )}
              </span>
            </div>
            {conversation?.session.salesforce_lead && (
              <>
                <div style={{position: 'absolute', right: 0, top: 0, bottom: 0}}>
                  <div
                    className='d-flex flex-stack'
                    style={{position: 'relative', height: '100%', paddingRight: 8}}
                  >
                    <div
                      className='d-flex'
                      style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src={toAbsoluteUrl('/media/svg/brand-logos/salesforce.svg')}
                        className='w-30px me-6'
                        alt=''
                      />
                      <a
                        className='mb-2'
                        href={conversation?.session.salesforce_lead.salesforce_url_to_lead}
                        target='_blank'
                        rel='noreferrer'
                      >
                        View on Salesforce
                      </a>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {conversation?.session.auth_user_connected &&
          conversation?.session.auth_user_connected.unique_identifier ===
            currentUser?.unique_identifier ? (
            <div className='d-flex flex-stack p-5'>
              <div className='d-flex align-items-center me-2'>
                <textarea
                  ref={textareaRef}
                  className='form-control form-control-flush mb-3'
                  rows={2}
                  data-kt-element='input'
                  placeholder='Write a reply...'
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={onKeyDown}
                  style={{resize: 'none'}}
                ></textarea>
              </div>
              <button
                className='btn btn-primary'
                type='button'
                data-kt-element='send'
                onClick={sendMessage}
              >
                Send
              </button>
            </div>
          ) : (
            <div className='d-flex flex-stack p-5' style={{justifyContent: 'center'}}>
              <div className='d-flex align-items-center me-2'>
                <button
                  className='btn btn-primary'
                  type='button'
                  data-kt-element='send'
                  onClick={connectToChat}
                >
                  Connect to Chat
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {DrawerMessenger}
