/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {Client, useAuth} from '../../../../app/modules/auth'
import {updateAiEnabled} from '../../../../app/modules/auth/core/_requests'
import TenantPicker from './TentantPicker'

interface AsideBottomItemProps {
  showClientPicker?: boolean
  showAgencyPicker?: boolean
}

const AsideBottomItem: React.FC<AsideBottomItemProps> = ({
  showClientPicker = false,
  showAgencyPicker = false,
}) => {
  const {
    auth,
    currentUser,
    currentClient,
    setCurrentClient,
    setSelectedClientClientId,
    setSelectedAgencyUniqueId,
  } = useAuth()

  async function updateAiChatbotEnabled(aiEnabled: boolean) {
    try {
      const {data: client} = await updateAiEnabled(aiEnabled)
      setCurrentClient(new Client(client))
    } catch (error: any) {
      console.error(error)
    }
  }

  return (
    <>
      {/* begin::Footer */}
      <div
        className='aside-footer flex-column-auto pt-5 pb-7 px-5'
        id='kt_aside_footer'
        style={{position: 'absolute', bottom: 0, width: '100%', backgroundColor: '#1e1e2d'}}
      >
        <>
          {showClientPicker && currentUser && currentUser.active_client_memberships?.length > 1 && (
            <TenantPicker
              title='Selected Client'
              memberships={currentUser.active_client_memberships}
              currentValue={auth?.selected_client_id || ''}
              setCurrentValue={setSelectedClientClientId}
            />
          )}
          {showAgencyPicker && currentUser && currentUser.active_agency_memberships?.length > 1 && (
            <TenantPicker
              title='Selected Operator'
              memberships={currentUser.active_agency_memberships}
              currentValue={auth?.selected_agency_unique_id || ''}
              setCurrentValue={setSelectedAgencyUniqueId}
            />
          )}
          {showClientPicker && currentClient && (
            <div
              className='mb-5'
              style={{
                backgroundColor: 'rgba(63, 66, 84, 0.35)',
                borderRadius: '0.475rem',
                color: '#b5b5c3',
              }}
            >
              <div className='row mb-0'>
                <div className='col-12'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='form-check form-switch form-check-custom form-check-solid'>
                      <label
                        className='form-check-label fw-bold fs-6'
                        htmlFor='flexSwitch30x50'
                        style={{
                          padding: '20px 0',
                          marginRight: 5,
                        }}
                      >
                        Enable AI
                      </label>
                      <input
                        style={{marginLeft: 5}}
                        className='form-check-input h-30px w-50px'
                        type='checkbox'
                        value=''
                        id='flexSwitch30x50'
                        checked={currentClient.ai_chatbot_enabled}
                        onChange={() => {
                          updateAiChatbotEnabled(!currentClient.ai_chatbot_enabled)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
        <a
          target='_blank'
          className='btn btn-custom btn-primary w-100'
          href={
            false && 'thisIsForThemeHelp'
              ? process.env.REACT_APP_PREVIEW_DOCS_URL
              : 'mailto:chris@bereave.ai'
          }
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss-='click'
          title='Check out the complete documentation with over 100 components'
        >
          <span className='btn-label'>Get Help</span>
          <span className='svg-icon btn-icon svg-icon-2'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' />
          </span>
        </a>
      </div>
      {/* end::Footer */}
    </>
  )
}

const AsideDefault: FC = () => {
  const {config, classes} = useLayout()
  const {auth} = useAuth()
  const {aside} = config

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          <Link to='/'>
            <img
              alt='Logo'
              className='h-25px logo'
              src='https://static.morto.ai/v1/img/logos/morto_logo_white%402x.png'
            />
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/'>
            <img
              alt='Logo'
              className='h-25px logo'
              src='https://static.morto.ai/v1/img/logos/morto_logo_black%402x.png'
            />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr079.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {auth?.selected_view_type === 'client' ? (
        <AsideBottomItem showClientPicker />
      ) : (
        <AsideBottomItem showAgencyPicker />
      )}
    </div>
  )
}

export {AsideDefault}
