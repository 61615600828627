/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'

import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {Dropdown1} from '../../../../_metronic/partials/content/dropdown/Dropdown1'
import {CodeBlock} from '../../../../_metronic/partials'

import {getClientEmails} from '../core/_requests'
import {useAuth} from '../../auth'
import {GetClientEmailsModel} from '../core/_models'
import {
  FeedsWidget2,
  FeedsWidget3,
  FeedsWidget4,
  FeedsWidget5,
  FeedsWidget6,
} from '../../../../_metronic/partials/widgets'

type Props = {}

const EmailsComponent: React.FC<Props> = () => {
  const {auth, currentClient} = useAuth()

  const currentUserWebsite = currentClient?.url_to_business

  // TODO: Support multi-tenant "has_completed_getting_started" value
  const [haveCompletedInstall, setHaveCompletedInstall] = useState<boolean>(
    currentClient?.has_completed_getting_started ?? false
  )

  const [error, setError] = useState<string | null>(null)
  const [code, setCode] = useState<string | null>(null)

  const [emailsResult, setEmailsResult] = useState<GetClientEmailsModel | null>(null)

  async function handleConfirmInstall() {
    /*
    setError(null)
    try {
      await markGettingStartedAsCompleted()
      setHaveCompletedInstall(true)
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || 'Something went wrong'
      setError(errorMessage)
    }
    */
  }

  async function loadDirectMailItems() {
    try {
      const {data: emailsResult} = await getClientEmails()
      setEmailsResult(new GetClientEmailsModel(emailsResult))
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || 'Unable to load emails'
      setError(errorMessage)
    }
  }

  useEffect(() => {
    loadDirectMailItems()
  }, [auth?.selected_client_id])

  useEffect(() => {
    setHaveCompletedInstall(currentClient?.has_completed_getting_started || false)
  }, [currentClient?.has_completed_getting_started])

  return (
    <div className='row g-5' style={{height: '100%'}}>
      <div className='col-lg-12'>
        <div className='card card-custom shadow mb-5'>
          <div className='card-header py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Emails</span>
              <span className='text-muted fw-bold fs-7'>
                Manage the emails you have received from your customers
              </span>
            </h3>
            <div className='card-toolbar'></div>
          </div>
          <div className='card-body'>
            {error && (
              <>
                <div className='alert alert-danger d-flex align-items-center p-5 mb-5'>
                  <span className='svg-icon svg-icon-2hx svg-icon-danger me-3'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen044.svg'
                      className='svg-icon svg-icon-2hx svg-icon-dark me-4'
                    />
                  </span>
                  <div className='d-flex flex-column'>
                    <h5 className='mb-1'>Error</h5>
                    <span>{error}</span>
                  </div>
                </div>
              </>
            )}
            {emailsResult && (
              <>
                <span className='fs-4 text-gray-800 me-2'>Your AI Assistant:</span>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <div className='fs-2 fw-bolder me-1 text-bolder mt-2'>
                          {emailsResult?.client_chatbot.getName()}
                        </div>
                        {false && (
                          <a
                            href='#'
                            className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                          >
                            {emailsResult?.client_chatbot.getName()}
                          </a>
                        )}
                        {false && 'verification_badge' && (
                          <a href='#'>
                            <KTSVG
                              path='/media/icons/duotune/general/gen026.svg'
                              className='svg-icon-1 svg-icon-primary'
                            />
                          </a>
                        )}
                        {false && (
                          <a
                            href='#'
                            className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_upgrade_plan'
                          >
                            Upgrade to Pro
                          </a>
                        )}
                      </div>

                      {true && (
                        <div className='d-flex flex-wrap fw-bold fs-6 pe-2'>
                          {false && (
                            <>
                              <a
                                href='#'
                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/communication/com006.svg'
                                  className='svg-icon-4 me-1'
                                />
                                Developer
                              </a>
                              <a
                                href='#'
                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen018.svg'
                                  className='svg-icon-4 me-1'
                                />
                                SF, Bay Area
                              </a>
                            </>
                          )}
                          <a
                            target={'_blank'}
                            href={`mailto:${emailsResult.client_chatbot.email_address}`}
                            className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                            rel='noreferrer'
                          >
                            <KTSVG
                              path='/media/icons/duotune/communication/com011.svg'
                              className='svg-icon-4 me-1'
                            />
                            {emailsResult.client_chatbot.email_address}
                          </a>
                        </div>
                      )}
                    </div>

                    {false && (
                      <div className='d-flex my-4'>
                        <a
                          href='#'
                          className='btn btn-sm btn-light me-2'
                          id='kt_user_follow_button'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr012.svg'
                            className='svg-icon-3 d-none'
                          />

                          <span className='indicator-label'>Follow</span>
                          <span className='indicator-progress'>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </a>
                        <a
                          href='#'
                          className='btn btn-sm btn-primary me-3'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_offer_a_deal'
                        >
                          Hire Me
                        </a>
                        <div className='me-0'>
                          <button
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                          >
                            <i className='bi bi-three-dots fs-3'></i>
                          </button>
                          <Dropdown1 />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className='d-flex flex-wrap flex-stack'>
                    {false && (
                      <div className='d-flex flex-column flex-grow-1 pe-8'>
                        <div className='d-flex flex-wrap'>
                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr066.svg'
                                className='svg-icon-3 svg-icon-success me-2'
                              />
                              <div className='fs-2 fw-bolder'>4500$</div>
                            </div>

                            <div className='fw-bold fs-6 text-gray-400'>Earnings</div>
                          </div>

                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr065.svg'
                                className='svg-icon-3 svg-icon-danger me-2'
                              />
                              <div className='fs-2 fw-bolder'>75</div>
                            </div>

                            <div className='fw-bold fs-6 text-gray-400'>Projects</div>
                          </div>

                          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                            <div className='d-flex align-items-center'>
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr066.svg'
                                className='svg-icon-3 svg-icon-success me-2'
                              />
                              <div className='fs-2 fw-bolder'>60%</div>
                            </div>

                            <div className='fw-bold fs-6 text-gray-400'>Success Rate</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {false && (
                      <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                        <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                          <span className='fw-bold fs-6 text-gray-400'>Profile Compleation</span>
                          <span className='fw-bolder fs-6'>50%</span>
                        </div>
                        <div className='h-5px mx-3 w-100 bg-light mb-3'>
                          <div
                            className='bg-success rounded h-5px'
                            role='progressbar'
                            style={{width: '50%'}}
                          ></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='separator mx-1 my-4'></div>
                <div className='mt-10'>
                  {emailsResult.emails.length === 0 ? (
                    <>
                      <div className='alert alert-warning d-flex align-items-center p-5 mb-5'>
                        <span className='svg-icon svg-icon-2hx svg-icon-warning me-3'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen044.svg'
                            className='svg-icon svg-icon-2hx svg-icon-dark me-4'
                          />
                        </span>
                        <div className='d-flex flex-column'>
                          <h5 className='mb-1'>Warning</h5>
                          <span>No Emails Have Been Received</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {emailsResult.emails.map((email) => {
                        return (
                          <div key={`email-${email.unique_identifier}`}>
                            <div className='row'>
                              <div className='col-lg-12'>
                                <h2 className='mb-2'>{email.subject}</h2>
                                <div>
                                  <span className='text-gray-600'>From:</span>{' '}
                                  <span className='fw-bold'>
                                    <a href={`mailto:${email.formatted_from_email_address}`} target="_blank" rel='noreferrer'>
                                      {email.formatted_from_email_address}
                                    </a>
                                  </span>
                                </div>
                                <div>
                                  <span className='text-gray-600'>To:</span>{' '}
                                  <span className='text-gray-600'>{`${email.to_emails}${
                                    email.cc_emails ? `, CC: ${email.cc_emails}` : ''
                                  }${email.bcc_emails ? `, BCC: ${email.bcc_emails}` : ''}`}</span>
                                </div>
                              </div>
                            </div>
                            <div className='row mt-5'>
                              <div className='col-lg-12'>
                                <div className='mb-5 fs-5'>{email.body}</div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className='row g-5 g-xl-8'>
      {/* begin::Col */}
      <div className='col-xl-6'>
        <FeedsWidget2 className='mb-5 mb-xl-8' />
        <FeedsWidget3 className='mb-5 mb-xl-8' />
        <FeedsWidget4 className='mb-5 mb-xl-8' />
      </div>
      {/* end::Col */}
      {/* begin::Col */}
      <div className='col-xl-6'>
        <FeedsWidget5 className='mb-5 mb-xl-8' />
        <FeedsWidget6 className='mb-5 mb-xl-8' />
      </div>
      {/* end::Col */}
    </div>
  )
}

export default EmailsComponent
