/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react'
import clsx from 'clsx'
import ReconnectingWebSocket from 'reconnecting-websocket'
import Identicon from 'react-identicons'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  toAbsoluteUrl,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../helpers'
import {IndividualConversationModel, Message} from '../../../app/modules/conversations/core/_models'
import {useAuth} from '../../../app/modules/auth'
import {getMessagesAfterUniqueIdentifier} from '../../../app/modules/conversations/core/_requests'

type Props = {
  isDrawer?: boolean
  messages?: Message[]
  setConversation?: (conversation: IndividualConversationModel) => void
}

export interface ChatInnerRefProps {
  onSendMessage: (message: string) => void
  connectToSession: () => void
}

const ChatInner = forwardRef(({isDrawer = false, setConversation}: Props, ref) => {
  const {auth, currentUser, selectedConversationSessionId} = useAuth()

  const scrollRef = useRef<HTMLDivElement>(null)
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)

  const [chatWebsocket, setChatWebsocket] = useState<ReconnectingWebSocket | null>(null)
  const [websocketConnected, setWebsocketConnected] = useState(false)
  const [lastServerInteraction, setLastServerInteraction] = useState<Date | null>(null)

  const [messages, setMessages] = useState<Message[]>([])
  const [hasMore, setHasMore] = useState(true)

  const messagesRef = useRef<Message[]>([])

  useImperativeHandle(ref, () => {
    return {
      onSendMessage,
      connectToSession,
    }
  })

  const fetchMoreData = async () => {
    if (!selectedConversationSessionId) {
      return
    }
    if (messages.length < 10) {
      setHasMore(false)
      return
    }
    try {
      const updatedMessages = await getMessagesAfterUniqueIdentifier(
        selectedConversationSessionId,
        messages[messages.length - 1].unique_identifier
      )
      setHasMore(updatedMessages.length >= 10)
      setMessages([...messages, ...updatedMessages])
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error?.response?.data?.error || 'Failed to retrieve messages'
      console.warn(errorMessage)
    }
  }

  function onSendMessage(message: string) {
    if (chatWebsocket) {
      chatWebsocket.send(
        JSON.stringify({
          type: 'chat_message',
          data: {
            message,
          },
        })
      )
    }
  }

  function connectToSession() {
    if (chatWebsocket) {
      chatWebsocket.send(
        JSON.stringify({
          type: 'connect_to_session',
          data: {
            when: new Date().getTime(),
          },
        })
      )
    }
  }

  useEffect(() => {
    const selectedClientId = auth?.selected_client_id
    const defaultClient = currentUser?.active_client_memberships[0]?.client

    if (
      !auth?.api_token ||
      !selectedConversationSessionId ||
      (!defaultClient?.client_id && !selectedClientId)
    ) {
      return
    }

    const clientId = selectedClientId || defaultClient?.client_id
    const appWsUrl = process.env.REACT_APP_WIDGET_WS_URL

    const websocketUrl = `${appWsUrl}/ws/chat/${clientId}/session/${selectedConversationSessionId}/?token=${auth?.api_token}`

    const websocket = new ReconnectingWebSocket(websocketUrl)
    setChatWebsocket(websocket)

    websocket.onopen = () => {
      setWebsocketConnected(true)
    }

    websocket.onclose = function (e) {
      setWebsocketConnected(false)
    }

    function setChatMessages(chatMessages: Message[]) {
      // Set messages to reverse
      const reversedMessages = chatMessages.reverse()
      setMessages(reversedMessages)
      setHasMore(reversedMessages.length >= 10)
      messagesRef.current = chatMessages
      setLastServerInteraction(new Date())
      if (scrollRef && scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
      }
    }

    websocket.onmessage = function (e) {
      // This is the websocket messages when connected directly to a chat session by unique identifier
      const data = JSON.parse(e.data)
      if (data && data.type && data.data) {
        if (data.type === 'connected') {
          setLastServerInteraction(new Date())
          if (data.data.initial_messages) {
            setChatMessages(data.data.initial_messages.map((x: any) => new Message(x)))
          } else {
            setChatMessages([])
          }
        } else if (data.type === 'chat_message') {
          setChatMessages([...messagesRef.current, new Message(data.data.message)])
        } else if (data.type === 'session_update') {
          console.log('data: ', data.data.session)
          if (setConversation) {
            setConversation(
              new IndividualConversationModel({
                session: data.data.session,
              })
            )
          }
        } else if (data.type === 'presence_update') {
          // TODO: Update the presence list
        } else {
          console.warn('Unknown ChatInner message type: ', data.type)
        }
      } else {
        console.warn('Unsupported message (500): ', data)
      }
    }

    return () => {
      websocket.close()
    }
  }, [
    currentUser?.active_client_memberships,
    selectedConversationSessionId,
    messagesRef,
    scrollRef,
    auth,
    auth?.selected_client_id,
    setConversation,
  ])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [messagesRef.current.length])

  return (
    <div
      id='scrollableDiv'
      style={{
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
    >
      <InfiniteScroll
        dataLength={messages.length}
        next={fetchMoreData}
        style={{display: 'flex', flexDirection: 'column-reverse'}} //To put endMessage and loader to the top.
        inverse={true} //
        hasMore={messages.length > 0 && hasMore}
        loader={<h4>Loading...</h4>}
        scrollableTarget='scrollableDiv'
        scrollThreshold={'500px'}
      >
        {messages.length > 0 ? (
          <>
            {messages.map((message, index) => (
              <div
                className={`d-flex d-flex ${
                  message.from_frontend_user ? 'justify-content-start' : 'justify-content-end'
                } mb-10 mb-10 px-5`}
                key={`message-${message.unique_identifier}`}
              >
                <div className='d-flex flex-column align-items align-items-start'>
                  <div className='d-flex align-items-center mb-2'>
                    <div className='symbol  symbol-35px symbol-circle '>
                      {message.from_frontend_user ? (
                        <Identicon
                          string={message.from_frontend_user?.anonymous_mnemonic_name || 'user'}
                          size={20}
                        />
                      ) : (
                        <img
                          src='https://static.morto.ai/v1/img/default_avatar.png'
                          alt='Default Avatar'
                          width='20'
                          height='20'
                        />
                      )}
                    </div>
                    <div className='ms-3'>
                      <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                        {message.getFromName(currentUser)}
                      </a>
                      <span className='text-muted fs-7 mb-1'></span>
                    </div>
                  </div>
                  <div
                    className='p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start'
                    data-kt-element='message-text'
                  >
                    {message.text}
                  </div>
                  {message.matching_classification_category &&
                    message.matching_classification_category_percentage_likelihood &&
                    message.matching_classification_category_percentage_likelihood > 50.0 && (
                      <>
                        <div className='text-muted fs-7 ms-1 mt-1'>
                          {`${message.matching_classification_category.name} - ${message.matching_classification_category_percentage_likelihood}%`}
                        </div>
                      </>
                    )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <>{/* TODO: Loading state /  empty state */}</>
        )}
      </InfiniteScroll>
    </div>
  )

  return (
    <div
      className='card-body overflow-auto'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        ref={scrollRef}
        className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >
        {messagesRef.current.map((message, index) => {
          const isFromOtherUser = !message.isFromAuthUser(currentUser)
          const state = isFromOtherUser ? 'info' : 'primary'
          const templateAttr = {}
          if (message.template) {
            Object.defineProperty(templateAttr, 'data-kt-element', {
              value: `template-${message.type}`,
            })
          }
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            isFromOtherUser ? 'start' : 'end'
          } mb-10`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${isFromOtherUser ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {isFromOtherUser ? (
                    <>
                      <div className='symbol  symbol-35px symbol-circle '>
                        {message.from_frontend_user ? (
                          <Identicon
                            string={message.from_frontend_user?.anonymous_mnemonic_name || 'user'}
                            size={20}
                          />
                        ) : (
                          <img
                            src='https://static.morto.ai/v1/img/default_avatar.png'
                            alt='Default Avatar'
                            width='20'
                            height='20'
                          />
                        )}
                      </div>
                      <div className='ms-3'>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                        >
                          {message.getFromName(currentUser)}
                        </a>
                        <span className='text-muted fs-7 mb-1'>{message.time}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='text-muted fs-7 mb-1'>{message.time}</span>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                        >
                          {message.getFromName(currentUser)}
                        </a>
                      </div>
                      <div className='symbol  symbol-35px symbol-circle '>
                        {message.from_frontend_user ? (
                          <Identicon
                            string={message.from_frontend_user?.anonymous_mnemonic_name || 'user'}
                            size={20}
                          />
                        ) : (
                          <img
                            src={message.getFromAvatar(currentUser)}
                            alt='Default Avatar'
                            width='20'
                            height='20'
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={clsx(
                    'p-5 rounded',
                    `bg-light-${state}`,
                    'text-dark fw-bold mw-lg-400px',
                    `text-${isFromOtherUser ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                  dangerouslySetInnerHTML={{__html: message.text}}
                ></div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
})

export {ChatInner}
