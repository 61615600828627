import {useCallback, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

import {CodeBlock} from '../../../../_metronic/partials'

import {Client} from '../../auth'
import {getWebsiteCode} from '../../auth/core/_requests'

interface ClientCodeBlockProps {
  client: Client
}

function ClientCodeBlock({client}: ClientCodeBlockProps) {
  const [code, setCode] = useState<string | null>(null)

  const [error, setError] = useState<string | null>(null)

  const loadGettingStartedInformation = useCallback(async () => {
    if (!client) {
      setError(null)
      setCode(null)
      return
    }
    try {
      const {data: gettingStarted} = await getWebsiteCode(client.unique_identifier)
      if (!gettingStarted || !gettingStarted.widget_script || !gettingStarted.widget_url) {
        setError('Invalid response')
      } else {
        const finalCode = `<!--Morto AI-->
<script id="morto-setup">
  var WIDGET_URL = "${gettingStarted.widget_url}";
  var APP_ID = "${client.client_id}";
  window.mortoAISettings = {
    app_id: APP_ID${
      process.env.NODE_ENV === 'production' ? '' : `, ws_url: "${gettingStarted.ws_url || ''}"`
    }
  };
</script>
<script id="morto-loader">
  (function() { var w = window; var hmp = w.MortoAI; if (typeof hmp === "function") { hmp("reattach_activator"); hmp("update", w.mortoAISettings); } else { var d = document; var mortoAI = function() { mortoAI.c(arguments); }; mortoAI.q = []; mortoAI.c = function(args) { mortoAI.q.push(args); }; w.MortoAIFromIndex = mortoAI; var onLoadEvent = function() { var s = d.createElement("script"); s.type = "text/javascript"; s.async = true; s.src = WIDGET_URL + APP_ID + "/"; var x = d.getElementsByTagName("script")[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent("onload", onLoadEvent); } else { w.addEventListener("load", onLoadEvent, false); } if (w.document.readyState === "complete") { onLoadEvent(); } } })();
</script>
<!--End Morto AI-->`
        setCode(finalCode)
        setError(null)
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || 'Unable to retrieve code'
      setError(errorMessage)
    }
  }, [client])

  useEffect(() => {
    loadGettingStartedInformation()
  }, [loadGettingStartedInformation])

  return (
    <>
      {error && (
        <>
          <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
            <span className='svg-icon svg-icon-2hx svg-icon-danger me-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen044.svg'
                className='svg-icon svg-icon-2hx svg-icon-dark me-4'
              />
            </span>
            <div className='d-flex flex-column'>
              <h5 className='mb-1'>Error</h5>
              <span>{error}</span>
            </div>
          </div>
        </>
      )}
      {code && client && (
        <div>
          <h4>{`Paste the following block of code anywhere on  ${client.getName()}'s website:`}</h4>
          <CodeBlock language='markup' code={code} />
        </div>
      )}
    </>
  )
}

export {ClientCodeBlock as default}
