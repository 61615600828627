import React from 'react'
import {useNavigate} from 'react-router-dom'

import {UserClientMembership, UserAgencyMembership} from '../../../../app/modules/auth'

interface TenantPickerProps {
  title: string
  memberships: UserClientMembership[] | UserAgencyMembership[]
  currentValue: string
  setCurrentValue: (value: string) => void
}

const TenantPicker: React.FC<TenantPickerProps> = ({
  title,
  memberships,
  currentValue,
  setCurrentValue,
}) => {
  const navigate = useNavigate()

  return (
    <div
      className='mb-5'
      style={{
        backgroundColor: 'rgba(63, 66, 84, 0.35)',
        borderRadius: '0.475rem',
        color: '#b5b5c3',
      }}
    >
      <div className='row mb-0'>
        <div className='col-12'>
          <div className='p-5'>
            <label
              htmlFor='exampleFormControlInput1'
              className='form-label text-center w-100'
              style={{color: '#b5b5c3'}}
            >
              {title}
            </label>
            <select
              className='form-select form-select-solid'
              aria-label='Select Client'
              value={currentValue}
              onChange={(e) => {
                setCurrentValue(e.target.value)
                // We redirect as well to not be left in a weird UI state with an old client / old agency
                navigate("/")
              }}
            >
              {memberships.map((membership) => {
                // Check to see if membership is a ClientMembership
                if (membership instanceof UserClientMembership) {
                  return (
                    <option
                      key={membership.client.client_id}
                      value={membership.client.client_id}
                      selected={membership.client.client_id === currentValue}
                    >
                      {membership.client.getName()}
                    </option>
                  )
                } else {
                  return (
                    <option
                      key={membership.agency.unique_identifier}
                      value={membership.agency.unique_identifier}
                      selected={membership.agency.unique_identifier === currentValue}
                    >
                      {membership.agency.getName()}
                    </option>
                  )
                }
              })}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TenantPicker
