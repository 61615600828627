/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'

import EmailsComponent from './components/Emails'

const EmailsWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Emails'}</PageTitle>
      <EmailsComponent />
    </>
  )
}

export {EmailsWrapper}
