import axios from 'axios'
import {
  RecentConversationsModel,
  AccountUsageModel,
  ConversationStatisticsModel,
  IndividualConversationModel,
  Message,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const RECENT_CONVERSATIONS_URL = `${API_URL}/conversations/recent/`
export const CONVERSATIONS_USAGE_URL = `${API_URL}/conversations/usage/`
export const CONVERSATION_STATISTICS_URL = `${API_URL}/conversations/statistics/`
export const INDIVIDUAL_CONVERSATION_URL = `${API_URL}/conversations/`

// Server should return RecentConversationsModel
export function getRecentConversations(isDashboard: boolean = false) {
  return axios.get<RecentConversationsModel>(
    RECENT_CONVERSATIONS_URL + `?isDashboard=${isDashboard ? 'yes' : 'no'}`
  )
}

export function getAccountUsage() {
  return axios.get<AccountUsageModel>(CONVERSATIONS_USAGE_URL)
}

export function getConversationStatistics() {
  return axios.get<ConversationStatisticsModel>(CONVERSATION_STATISTICS_URL)
}

export function getIndividualConversation(sessionId: string) {
  return axios.get<IndividualConversationModel>(`${INDIVIDUAL_CONVERSATION_URL}${sessionId}/`)
}

export function getMessagesAfterUniqueIdentifier(sessionId: string, uniqueIdentifier: string) {
  const response = axios.get<Message[]>(
    `${INDIVIDUAL_CONVERSATION_URL}${sessionId}/messages/after/${uniqueIdentifier}/`
  )
  return response
    .then((response: any) => response.data)
    .then((response: any) => {
      return response.data.map((item: any) => new Message(item))
    })
}
