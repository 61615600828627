import {useCallback, useEffect, useState} from 'react'

import {useAuth} from '../../auth'
import {IndividualConversationModel} from './_models'
import {getIndividualConversation} from './_requests'

const useActiveConversation = () => {
  const {selectedConversationSessionId, setSelectedConversationSessionId} = useAuth()
  const [conversation, setConversation] = useState<IndividualConversationModel | null>(null)

  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const loadConversation = useCallback(async () => {
    if (!selectedConversationSessionId) {
      setConversation(null)
      return
    }
    setLoading(true)
    try {
      const {data: updatedConversation} = await getIndividualConversation(
        selectedConversationSessionId
      )
      // If anything changes in the API property-wise it needs to be reflected in IndividualConversationModel
      const conversationObject = new IndividualConversationModel(updatedConversation)
      setConversation(conversationObject)
      setError(null)
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || 'Failed to retrieve conversation'
      setError(errorMessage)
    }
    setLoading(false)
  }, [selectedConversationSessionId])

  useEffect(() => {
    loadConversation()
  }, [loadConversation])

  return {
    conversation,
    setConversation,
  }
}

export {useActiveConversation}
