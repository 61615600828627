/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {
  getUserByToken,
  requestAccount,
  requestAccountResendCode,
  requestAccountPhoneVerify,
} from '../core/_requests'
import {Link} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {UserModel} from '../core/_models'

interface PhoneVerificationProps {
  phoneNumber: string
  onCancel: () => void
}

const initialRequestValues = {
  firstname: '',
  lastname: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
}

const initialVerificationValues = {
  phoneNumber: '',
  code: '',
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(255, 'Maximum 255 characters')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(255, 'Maximum 255 characters')
    .required('Email is required'),
  phoneNumber: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 255 characters')
    .required('Phone is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(255, 'Maximum 255 characters')
    .required('Last name is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 characters')
      .max(255, 'Maximum 255 characters')
      .required('Password is required'),
      confirmPassword: Yup.string()
      .required('Password confirmation is required')
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref('password')], "Passwords don't match"),
      }),
})

const codeVerificationSchema = Yup.object().shape({
  phoneNumber: Yup.string(),
  code: Yup.string().required('Code is required'),
})

function FinishedRequesting() {
  return (
    <div className='w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      {/* begin::Heading */}
      <div className='mb-1 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Successfully Requested Account</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      <div className='text-center'>
        <h4 className='mt-10 mb-10'>{`You have successfully requested an account.`}</h4>
        <h4>{`We will be in touch with further instructions to login and finish setting up your account.`}</h4>
      </div>
    </div>
  )
}

function PhoneVerification({
  phoneNumber,
  onCancel,
}: PhoneVerificationProps): JSX.Element {
  const [loading, setLoading] = useState(false)
  const [finished, setFinished] = useState(false)
  const [resendingCode, setResendingCode] = useState(false)

  const formik = useFormik({
    initialValues: {
      ...initialVerificationValues,
      phoneNumber,
    },
    validationSchema: codeVerificationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await requestAccountPhoneVerify(values.phoneNumber, values.code)
        setFinished(true)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error?.response?.data?.error || 'Unable to verify phone. Please try again.'
        setStatus(errorMessage)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  async function onResendCode() {
    setResendingCode(true)
    try {
      await requestAccountResendCode(phoneNumber)
    } catch (error: any) {
      console.warn('Failed to resend code: ', error)
    }
    setResendingCode(false)
  }

  if (finished) {
    return <FinishedRequesting />
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-1 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Verify Phone</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold text-center'>{formik.status}</div>
        </div>
      )}

      <div className='mb-5 text-center'>
        <p>{`We just texted ${phoneNumber}.`}</p>
        <p>{`Enter the code below to verify your phone number.`}</p>
      </div>

      <input type='hidden' name='phoneNumber' value={formik.values.phoneNumber} />

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Verification Code</label>
        <input
          placeholder='Enter verification code'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.code && formik.errors.code},
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          autoFocus
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.code}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button
          type='button'
          id='kt_login_signup_form_cancel_button'
          className='btn btn-lg btn-light w-100 mb-5'
          onClick={onCancel}
        >
          Back
        </button>
        <button
          type='button'
          className='btn btn-lg btn-light w-100 mb-5'
          onClick={onResendCode}
        >
          {!resendingCode && <span className='indicator-label'>Resend Code</span>}
          {resendingCode && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}

export function RequestAccount() {
  const [loading, setLoading] = useState(false)
  const [verifyingPhoneNumber, setVerifyingPhoneNumber] = useState('')

  const formik = useFormik({
    initialValues: initialRequestValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data} = await requestAccount(
          values.email,
          values.firstname,
          values.lastname,
          values.phoneNumber,
          values.password
        )
        console.log('data: ', data)
        if (!data.phone_number) {
          throw new Error('Unable to request account. Please try again.')
        }
        setVerifyingPhoneNumber(data.phone_number)
        setLoading(false)
      } catch (error: any) {
        console.warn(error)
        const errorMessage =
          error?.response?.data?.error || 'Unable to request account. Please try again.'
        setStatus(errorMessage)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  if (verifyingPhoneNumber) {
    return (
      <PhoneVerification
        phoneNumber={verifyingPhoneNumber}
        onCancel={() => {
          setVerifyingPhoneNumber('')
        }}
      />
    )
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Request an Account</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Login
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold text-center'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-0'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>First name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
            <input
              placeholder='Last name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastname')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.lastname && formik.errors.lastname,
                },
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastname}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label>
        <input
          placeholder='Phone Number'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('phoneNumber')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber},
            {
              'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
            }
          )}
        />
        {formik.touched.phoneNumber && formik.errors.phoneNumber && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phoneNumber}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      
      {/* begin::Form group Password */}
      <div className='mb-7 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      
      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
