/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

import DirectMail from './components/DirectMail'

const DirectMailWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DIRECT_MAIL'})}</PageTitle>
      <DirectMail />
    </>
  )
}

export {DirectMailWrapper}
