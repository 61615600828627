/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'

import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {Dropdown1} from '../../../../_metronic/partials/content/dropdown/Dropdown1'
import {CodeBlock} from '../../../../_metronic/partials'

import {getDirectMailItems} from '../core/_requests'
import {useAuth} from '../../auth'
import {GetDirectMailModel} from '../core/_models'

type Props = {}

const DirectMail: React.FC<Props> = () => {
  const {auth, currentClient} = useAuth()

  const currentUserWebsite = currentClient?.url_to_business

  // TODO: Support multi-tenant "has_completed_getting_started" value
  const [haveCompletedInstall, setHaveCompletedInstall] = useState<boolean>(
    currentClient?.has_completed_getting_started ?? false
  )

  const [error, setError] = useState<string | null>(null)
  const [code, setCode] = useState<string | null>(null)

  const [directMailResult, setDirectMailResult] = useState<GetDirectMailModel | null>(null)

  async function handleConfirmInstall() {
    /*
    setError(null)
    try {
      await markGettingStartedAsCompleted()
      setHaveCompletedInstall(true)
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || 'Something went wrong'
      setError(errorMessage)
    }
    */
  }

  async function loadDirectMailItems() {
    try {
      const {data: directMailResult} = await getDirectMailItems()
      console.log('directMailResult', directMailResult)
      setDirectMailResult(directMailResult)
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error || 'Unable to load direct mail items'
      setError(errorMessage)
    }
  }

  useEffect(() => {
    loadDirectMailItems()
  }, [auth?.selected_client_id])

  useEffect(() => {
    setHaveCompletedInstall(currentClient?.has_completed_getting_started || false)
  }, [currentClient?.has_completed_getting_started])

  return (
    <div className='row g-5' style={{height: '100%'}}>
      <div className='col-lg-12'>
        <div className='card card-custom shadow mb-5'>
          <div className='card-header py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>Direct Mail</span>
              <span className='text-muted fw-bold fs-7'>
                Manage your direct mail marketing campaigns
              </span>
            </h3>
            <div className='card-toolbar'></div>
          </div>
          <div className='card-body'>
            {error && (
              <>
                <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
                  <span className='svg-icon svg-icon-2hx svg-icon-danger me-3'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen044.svg'
                      className='svg-icon svg-icon-2hx svg-icon-dark me-4'
                    />
                  </span>
                  <div className='d-flex flex-column'>
                    <h5 className='mb-1'>Error</h5>
                    <span>{error}</span>
                  </div>
                </div>
              </>
            )}
            {directMailResult && (
              <div className=''>
                {directMailResult.campaigns.length === 0 ? (
                  <>
                    <div className='alert alert-warning d-flex align-items-center p-5 mb-10'>
                      <span className='svg-icon svg-icon-2hx svg-icon-warning me-3'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen044.svg'
                          className='svg-icon svg-icon-2hx svg-icon-dark me-4'
                        />
                      </span>
                      <div className='d-flex flex-column'>
                        <h5 className='mb-1'>Warning</h5>
                        <span>No Direct Mail Campaigns Have Been Created</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {directMailResult.campaigns.map((x) => {
                      return (
                        <div>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <h2 className='mb-5'>{x.name}</h2>
                            </div>
                          </div>
                          <ul>
                            <li className='py-5'>
                              <h4>
                                Your direct mail phone number is:{' '}
                                <a href={`tel:${x.twilio_number}`} target='_blank' rel='noreferrer'>
                                  {x.twilio_number}
                                </a>
                              </h4>
                            </li>
                            {x.flyer_image_url && (
                              <>
                                <li className='py-5'>
                                  <h4>Your currently active direct mail flyer:</h4>
                                </li>
                                <li style={{listStyle: 'none'}}>
                                  <div className='rounded border p-10'>
                                    <div className='row'>
                                      <div className='col-lg-6 offset-lg-3'>
                                        <img
                                          src={x.flyer_image_url}
                                          alt='Direct Mail Flyer'
                                          style={{width: '100%'}}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {false && 'showWhenOnNewPage' && (
                                    <div className='py-5'>
                                      <small>
                                        Note: this quick-start can always be found by clicking{' '}
                                        <Link to='/get_started'>Get Started</Link>.
                                      </small>
                                    </div>
                                  )}
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      )
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DirectMail
