export interface AuthModel {
  api_token: string
  refreshToken?: string
  selected_view_type?: 'agency' | 'client'
  selected_client_id?: string // We use client_id instead of unique_id because of the websocket routes
  selected_agency_unique_id?: string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

type HelpText = {
  [key: string]: string
}

export class PricingResponseItem {
  local_id: string // So that we avoid working with indicies
  match_words: string
  answer_with_response: string

  constructor(data: any) {
    this.local_id = data.local_id
    this.match_words = data.match_words || ''
    this.answer_with_response = data.answer_with_response || ''
  }
}

export class Brand {
  unique_identifier: string
  name: string
  short_name?: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.name = data.name
    this.short_name = data.short_name
  }
}

export class PricingResponse {
  default_response: string
  active_items: PricingResponseItem[]

  constructor(data: any) {
    this.default_response = data.default_response || ''
    this.active_items = (data.active_items || []).map((item: any) => new PricingResponseItem(item))
  }
}

export class Agency {
  unique_identifier: string
  name: string
  short_name: string
  avatar_url?: string
  url_to_business?: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.name = data.name
    this.short_name = data.short_name
    this.avatar_url = data.avatar_url || null
    this.url_to_business = data.url_to_business || null
  }

  static helpText(key: string): string {
    const helpText: HelpText = {
      name: '',
      short_name: '',
    }
    return helpText[key] || ''
  }

  getName(): string {
    if (this.short_name) {
      return this.short_name
    }
    return this.name
  }
}

export class ICreateNewClient {
  unique_identifier?: string // Undefined if it's a new client

  fullClientName?: string
  shortenedClientName?: string
  cityAndState?: string
  businessPhoneNumber?: string
  businessWebsite?: string

  // Inconsistency in the casing here because I'm a bad programmer -CC

  type_of_client_unique_id?: string
  type_of_client_string?: string
  other_client_type_unique_id?: string

  // Whether or not we should go through the agency memberships and find
  // people who have the "all clients" permission. We hide this checkbox for now
  // but it's stubbed out here for when we hook it up.

  addAllUsersToClient?: boolean
}

export class Client {
  avatar_url?: string

  brand?: Brand
  agency?: Agency

  has_completed_getting_started: boolean
  ai_chatbot_enabled: boolean
  business_name: string
  business_short_name?: string
  chatbot_name: string
  chatbot_pronoun: string
  client_id: string
  location: string
  name: string
  type_of_business: string
  unique_identifier: string
  url_to_business: string | null
  business_phone: string | null

  zapier_is_enabled?: boolean
  salesforce_is_connected?: boolean
  service_fusion_is_connected?: boolean
  slack_is_enabled?: boolean
  slack_webhook_url?: string

  pricing_active_response?: PricingResponse | null

  constructor(data: any) {
    this.has_completed_getting_started = data.has_completed_getting_started
    this.ai_chatbot_enabled = data.ai_chatbot_enabled
    this.business_name = data.business_name
    this.business_short_name = data.business_short_name
    this.chatbot_name = data.chatbot_name
    this.chatbot_pronoun = data.chatbot_pronoun
    this.client_id = data.client_id
    this.location = data.location
    this.name = data.name
    this.type_of_business = data.type_of_business
    this.unique_identifier = data.unique_identifier
    this.url_to_business = data.url_to_business
    this.business_phone = data.business_phone
    if (data.brand) {
      this.brand = new Brand(data.brand)
    }
    if (data.agency) {
      this.agency = new Agency(data.agency)
    }
    this.zapier_is_enabled = data.zapier_is_enabled || false
    this.salesforce_is_connected = data.salesforce_is_connected || false
    this.service_fusion_is_connected = data.service_fusion_is_connected || false
    this.slack_is_enabled = data.slack_is_enabled || false
    this.slack_webhook_url = data.slack_webhook_url || null
    this.pricing_active_response = data.pricing_active_response
      ? new PricingResponse(data.pricing_active_response)
      : null
  }

  static helpText(key: string): string {
    const helpText: HelpText = {
      company_name:
        'The name of the top-most entity of your organization. This is not used in any AI prompts.',
      business_name:
        'Can be used in message sequences with {{ CLIENT_BUSINESS_NAME }}. This will feed the AI prompt.',
      business_short_name:
        'Can be used in message sequences with {{ CLIENT_BUSINESS_SHORT_NAME }}. Optional.',
      type_of_business:
        'Feeds the AI prompt. Typically "Funeral Home", "Crematory", "Cemetery", "Mortuary", or "Other".',
      location: 'Simple location (e.g Indianapolis, IN). This will feed the AI prompt.',
      chatbot_name: 'Can be used in message sequences with {{ CLIENT_CHATBOT_NAME }}. Required.',
      chatbot_pronoun:
        'Can be used in message sequences with {{ CLIENT_CHATBOT_PRONOUN }}. Required.',
    }
    return helpText[key] || ''
  }

  getName(): string {
    if (this.business_name) {
      return this.business_name
    }
    if (this.business_short_name) {
      return this.business_short_name
    }
    return this.name
  }

  getShortName(): string {
    if (this.business_short_name) {
      return this.business_short_name
    }
    return this.name
  }
}

export class UserClientMembership {
  role: string
  role_display: string
  client: Client

  constructor(data: any) {
    this.role = data.role
    this.role_display = data.role_display
    this.client = new Client(data.client)
  }
}

export class UserAgencyMembership {
  role: string
  role_display: string
  agency: Agency

  constructor(data: any) {
    this.role = data.role
    this.role_display = data.role_display
    this.agency = new Agency(data.agency)
  }
}

export class UserModel {
  id: number
  username: string
  password: string | undefined
  email: string
  first_name: string
  last_name: string
  fullname?: string
  occupation?: string
  companyName?: string
  roles?: Array<number>
  pic?: string
  language?: 'en' | 'de' | 'es' | 'fr' | 'ja' | 'zh' | 'ru'
  timeZone?: string
  website?: 'https://keenthemes.com'
  emailSettings?: UserEmailSettingsModel
  auth?: AuthModel
  communication?: UserCommunicationModel
  address?: UserAddressModel
  socialNetworks?: UserSocialNetworksModel
  active_client_memberships: UserClientMembership[]
  active_agency_memberships: UserAgencyMembership[]
  unique_identifier: string
  avatar_url: string
  name: string
  selected_client_client_id: string | undefined
  phone_number?: string
  phone_number_verified: boolean
  date_last_active?: string // Converted into Date/moment downstream

  constructor(user: any) {
    this.id = user.id
    this.username = user.username
    this.password = user.password
    this.email = user.email
    this.first_name = user.first_name
    this.last_name = user.last_name
    this.fullname = user.fullname
    this.occupation = user.occupation
    this.companyName = user.companyName
    this.roles = user.roles
    this.pic = user.pic
    this.language = user.language
    this.timeZone = user.timeZone
    this.website = user.website
    this.emailSettings = user.emailSettings
    this.auth = user.auth
    this.communication = user.communication
    this.address = user.address
    this.socialNetworks = user.socialNetworks
    this.active_client_memberships = user.active_client_memberships.map(
      (x: any) => new UserClientMembership(x)
    )
    this.active_agency_memberships = user.active_agency_memberships.map(
      (x: any) => new UserAgencyMembership(x)
    )
    this.unique_identifier = user.unique_identifier
    this.avatar_url = user.avatar_url
    this.name = user.name
    this.phone_number = user.phone_number || null
    this.phone_number_verified = !!user.phone_number_verified
    this.date_last_active = user.date_last_active || null
  }

  getName(): string {
    if (this.name) {
      return this.name
    }
    if (this.first_name || this.last_name) {
      return `${this.first_name} ${this.last_name}`.trim()
    }
    return this.email
  }
}

class ClientMembershipNotificationPreference {
  notification_type: 'text' | 'email' | 'both'

  at_need_lead_received: boolean
  pre_need_lead_received: boolean
  ai_conversation_lead_received: boolean

  constructor(data: any) {
    this.notification_type = data.notification_type
    this.at_need_lead_received = data.at_need_lead_received
    this.pre_need_lead_received = data.pre_need_lead_received
    this.ai_conversation_lead_received = data.ai_conversation_lead_received
  }

  getEnabledString(): string {
    const enabled = [
      this.at_need_lead_received && 'At-Need',
      this.pre_need_lead_received && 'Pre-Need',
      this.ai_conversation_lead_received && 'AI Conversation',
    ].filter((x) => x)
    if (enabled.length === 0) {
      return 'None'
    }
    return enabled.join(', ')
  }
}

export class ClientMembership {
  role: 'owner' | 'admin' | 'member'
  role_display: string
  client: Client
  user: UserModel
  notification_preferences: ClientMembershipNotificationPreference[]

  constructor(data: any) {
    this.role = data.role
    this.role_display = data.role_display
    this.client = new Client(data.client)
    this.user = new UserModel(data.user)
    this.notification_preferences = data.notification_preferences
      ? data.notification_preferences.map((x: any) => new ClientMembershipNotificationPreference(x))
      : []
  }

  getTextNotificationPreference(): ClientMembershipNotificationPreference | null {
    const matchingItems = this.notification_preferences.filter(
      (x) => x.notification_type === 'text'
    )
    return matchingItems.length > 0 ? matchingItems[0] : null
  }
}

export class AgencyMembership {
  role: 'owner' | 'admin' | 'member'
  role_display: string
  agency: Agency
  user: UserModel
  access_to_all_clients: boolean
  clients_short_string: string
  notification_preferences_short_string: string
  notification_type_at_need_lead_received: boolean
  notification_type_pre_need_lead_received: boolean
  notification_type_ai_lead_received: boolean

  constructor(data: any) {
    this.role = data.role
    this.role_display = data.role_display
    this.agency = new Agency(data.agency)
    this.user = new UserModel(data.user)
    this.access_to_all_clients = data.access_to_all_clients
    this.clients_short_string = data.clients_short_string
    this.notification_preferences_short_string = data.notification_preferences_short_string
    this.notification_type_at_need_lead_received = data.notification_type_at_need_lead_received
    this.notification_type_pre_need_lead_received = data.notification_type_pre_need_lead_received
    this.notification_type_ai_lead_received = data.notification_type_ai_lead_received
  }
}

export interface RequestAccountModel {
  phone_number: string
}

export class ClientType {
  unique_identifier: string
  name: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.name = data.name
  }
}

export class MessageClassificationCategory {
  unique_identifier: string
  name: string
  category_id: number
  help_text?: string

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.name = data.name
    this.category_id = data.category_id
    this.help_text = data.help_text
  }
}

export class MessageClassificationClientResponse {
  unique_identifier: string
  response: string
  client: Client

  constructor(data: any) {
    this.unique_identifier = data.unique_identifier
    this.response = data.response
    this.client = new Client(data.client)
  }
}

export class ClientAIResponsesModel {
  message_classification_category: MessageClassificationCategory
  client_response?: MessageClassificationClientResponse | null
  alternative_client_responses: MessageClassificationClientResponse[]

  constructor(data: any) {
    this.message_classification_category = new MessageClassificationCategory(
      data.message_classification_category
    )
    this.client_response = data.client_response
      ? new MessageClassificationClientResponse(data.client_response)
      : null
    this.alternative_client_responses = (data.alternative_client_responses || []).map(
      (x: any) => new MessageClassificationClientResponse(x)
    )
  }
}
